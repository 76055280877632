import styled from '@emotion/styled';
import { Colors } from 'shared/styles/colos';

export const Container = styled.div`
	background: linear-gradient(50deg, #00b41d 0%, #cdffd5 50%);
	display: flex;
	position: fixed;
	left: 29px;
	bottom: 29px;
	height: 83px;
	width: 264px;
	border-radius: 56px;
	z-index: 10;
`;

export const Botao = styled.button`
	align-items: center;
	background: ${Colors.white};
	border-radius: 56px;
	border: none;
	bottom: 32px;
	box-shadow: 8px 16px 36px rgba(78, 61, 53, 0.25);
	display: flex;
	gap: 8px;
	height: 78px;
	left: 32px;
	outline: none;
	padding: 16px;
	position: fixed;
	transition: 0.4s;
	width: 259px;
	z-index: 10;

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}
`;

export const ContainerTexto = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	& span {
		font-family: Inter;
		font-style: normal;

		&:first-of-type {
			color: #4e3b35;
			font-size: 16px;
			font-weight: 700;
			line-height: 19px;
		}

		&:last-of-type {
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			color: #96847f;
			text-align: start;
		}
	}
`;
