import styled from '@emotion/styled';

export const Botao = styled.button`
	align-items: center;
	background: linear-gradient(135deg, #cdffd5 0%, #00b41d 100%);
	border-radius: 56px;
	border: none;
	bottom: 39px;
	box-shadow: 8px 16px 36px rgba(78, 61, 53, 0.25);
	display: flex;
	height: 72px;
	justify-content: center;
	left: 29px;
	outline: none;
	position: fixed;
	transition: 0.4s;
	width: 72px;
	z-index: 10;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;
