import { Layout } from 'components/Layout/Layout';
import { Lazypage } from 'components/LazyPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { APP_PATH } from './path.routes';

export const AppRoutes = () => {
	return (
		<Routes>
			<Route element={<Layout />}>
				<Route path={APP_PATH.Main} element={Lazypage(() => import('pages/Main/MainView'))} />
				<Route path={APP_PATH.Produtos} element={Lazypage(() => import('pages/Produtos/ProdutosView'))} />
				<Route path="*" element={<Navigate to={APP_PATH.Main} replace />} />
			</Route>
		</Routes>
	);
};
