import { css } from '@emotion/react';
import styled from '@emotion/styled';

export type MediaMatchProps = {
	lessThan?: string;
	greaterThan?: string;
};

export const MediaMatch = styled.div<MediaMatchProps>`
	${({ lessThan, greaterThan }) => css`
		display: none;
		${!!lessThan && mediaMatchModifiers.lessThan(lessThan)}
		${!!greaterThan && mediaMatchModifiers.greaterThan(greaterThan)}
	`}
`;

const mediaMatchModifiers = {
	lessThan: (size: string) => css`
		@media (max-width: ${size}) {
			display: block;
		}
	`,

	greaterThan: (size: string) => css`
		@media (min-width: ${size}) {
			display: block;
		}
	`,
};
