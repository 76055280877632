export const IconeWhatsAppPadrao = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_349_6096)">
				<path
					d="M2.98762 30.3005L9.61165 28.5593C11.5995 29.5706 13.8067 30.1067 16.0534 30.1067C23.8857 30.1067 30.2667 23.7256 30.2667 15.8934C30.2667 12.096 28.7889 8.52197 26.1084 5.84077C23.4265 3.14495 19.8513 1.66669 16.0534 1.66669C8.22111 1.66669 1.84007 8.04774 1.84007 15.88C1.84007 18.2473 2.42724 20.5509 3.52848 22.6091L1.76855 29.0706L1.3137 30.7405L2.98762 30.3005Z"
					fill="url(#paint0_linear_349_6096)"
					stroke="#EEEEEE"
					strokeWidth="2"
				/>
				<path
					d="M11.3733 9.77332C11.16 9.77332 10.8 9.85332 10.4933 10.1866C10.2 10.52 9.33334 11.3333 9.33334 12.9466C9.33334 14.5733 10.52 16.1333 10.6667 16.36C10.8533 16.5866 13.0133 19.92 16.3333 21.3333C17.12 21.6933 17.7333 21.8933 18.2133 22.04C19 22.2933 19.72 22.2533 20.2933 22.1733C20.9333 22.08 22.24 21.3733 22.52 20.6C22.8 19.8266 22.8 19.1733 22.72 19.0266C22.6267 18.8933 22.4133 18.8133 22.08 18.6666C21.7467 18.48 20.12 17.68 19.8267 17.5733C19.52 17.4666 19.3333 17.4133 19.08 17.7333C18.8667 18.0666 18.2267 18.8133 18.04 19.0266C17.84 19.2533 17.6533 19.28 17.3333 19.12C16.9867 18.9466 15.92 18.6 14.6667 17.48C13.68 16.6 13.0267 15.52 12.8267 15.1866C12.6667 14.8666 12.8133 14.6666 12.9733 14.52C13.12 14.3733 13.3333 14.1333 13.4667 13.9333C13.64 13.7466 13.6933 13.6 13.8 13.3866C13.9067 13.16 13.8533 12.9733 13.7733 12.8133C13.6933 12.6666 13.0267 11.0133 12.7467 10.36C12.48 9.71998 12.2133 9.79998 12 9.78665C11.8133 9.78665 11.6 9.77332 11.3733 9.77332Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_349_6096"
					x1="2.7334"
					y1="2.66669"
					x2="29.3997"
					y2="29.1997"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CDFFD5" />
					<stop offset="1" stopColor="#00B41D" />
				</linearGradient>
				<clipPath id="clip0_349_6096">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
