export const IconeInstagramBotao = () => {
	return (
		<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.39996 0.666504H19.6C23.8666 0.666504 27.3333 4.13317 27.3333 8.39984V19.5998C27.3333 21.6508 26.5185 23.6178 25.0683 25.0681C23.618 26.5184 21.651 27.3332 19.6 27.3332H8.39996C4.13329 27.3332 0.666626 23.8665 0.666626 19.5998V8.39984C0.666626 6.34883 1.48139 4.38183 2.93167 2.93155C4.38195 1.48126 6.34895 0.666504 8.39996 0.666504ZM8.13329 3.33317C6.86025 3.33317 5.63936 3.83888 4.73918 4.73906C3.83901 5.63923 3.33329 6.86013 3.33329 8.13317V19.8665C3.33329 22.5198 5.47996 24.6665 8.13329 24.6665H19.8666C21.1397 24.6665 22.3606 24.1608 23.2607 23.2606C24.1609 22.3604 24.6666 21.1395 24.6666 19.8665V8.13317C24.6666 5.47984 22.52 3.33317 19.8666 3.33317H8.13329ZM21 5.33317C21.442 5.33317 21.8659 5.50877 22.1785 5.82133C22.491 6.13389 22.6666 6.55781 22.6666 6.99984C22.6666 7.44186 22.491 7.86579 22.1785 8.17835C21.8659 8.49091 21.442 8.6665 21 8.6665C20.5579 8.6665 20.134 8.49091 19.8214 8.17835C19.5089 7.86579 19.3333 7.44186 19.3333 6.99984C19.3333 6.55781 19.5089 6.13389 19.8214 5.82133C20.134 5.50877 20.5579 5.33317 21 5.33317ZM14 7.33317C15.7681 7.33317 17.4638 8.03555 18.714 9.28579C19.9642 10.536 20.6666 12.2317 20.6666 13.9998C20.6666 15.7679 19.9642 17.4636 18.714 18.7139C17.4638 19.9641 15.7681 20.6665 14 20.6665C12.2318 20.6665 10.5362 19.9641 9.28591 18.7139C8.03567 17.4636 7.33329 15.7679 7.33329 13.9998C7.33329 12.2317 8.03567 10.536 9.28591 9.28579C10.5362 8.03555 12.2318 7.33317 14 7.33317ZM14 9.99984C12.9391 9.99984 11.9217 10.4213 11.1715 11.1714C10.4214 11.9216 9.99996 12.939 9.99996 13.9998C9.99996 15.0607 10.4214 16.0781 11.1715 16.8283C11.9217 17.5784 12.9391 17.9998 14 17.9998C15.0608 17.9998 16.0782 17.5784 16.8284 16.8283C17.5785 16.0781 18 15.0607 18 13.9998C18 12.939 17.5785 11.9216 16.8284 11.1714C16.0782 10.4213 15.0608 9.99984 14 9.99984Z"
				fill="white"
			/>
		</svg>
	);
};
