import { Global } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes/app.routes';
import { EstilosGlobais } from 'shared/styles/global';

export const App = () => {
	return (
		<BrowserRouter>
			<Global styles={EstilosGlobais} />
			<AppRoutes />
		</BrowserRouter>
	);
};
