import { BotaoInstagram } from 'components/BotaoInstagram/BotaoInstagram';
import { BotaoWhatsAppMobile } from 'components/BotaoWhatsApp/BotaoWhatsAppMobile';
import { BotaoWhatsAppWeb } from 'components/BotaoWhatsApp/BotaoWhatsAppWeb';
import { MediaMatch } from 'components/MediaMatch/MediaMatch';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
	return (
		<>
			<Outlet />

			<BotaoInstagram />

			<MediaMatch greaterThan="899px">
				<BotaoWhatsAppWeb />
			</MediaMatch>

			<MediaMatch lessThan="899px">
				<BotaoWhatsAppMobile />
			</MediaMatch>
		</>
	);
};
