import styled from '@emotion/styled';

export const Container = styled.button`
	align-items: center;
	background: linear-gradient(135deg, #8805dd 0%, #fa008a 51.35%, #fec400 100%);
	border-radius: 56px;
	border: none;
	bottom: 120px;
	box-shadow: 8px 16px 36px rgba(78, 61, 53, 0.25);
	cursor: pointer;
	display: flex;
	height: 72px;
	justify-content: center;
	left: 29px;
	outline: none;
	position: fixed;
	width: 72px;
	z-index: 10;
`;
