type Props = {
	cor?: string;
};

export const IconeWhatsAppTransparente = ({ cor = '#FFF' }: Props) => {
	return (
		<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.0533 0.666687C6.77333 0.666687 0.839997 6.60002 0.839997 13.88C0.839997 16.2134 1.45333 18.48 2.6 20.48L0.73333 27.3334L7.73333 25.4934C9.66666 26.5467 11.84 27.1067 14.0533 27.1067C21.3333 27.1067 27.2667 21.1734 27.2667 13.8934C27.2667 10.36 25.8933 7.04002 23.4 4.54669C20.9067 2.04002 17.5867 0.666687 14.0533 0.666687ZM14.0667 2.89335C17 2.89335 19.7467 4.04002 21.8267 6.12002C23.8933 8.20002 25.04 10.96 25.04 13.8934C25.04 19.9467 20.1067 24.8667 14.0533 24.8667C12.08 24.8667 10.1467 24.3467 8.46666 23.3334L8.06666 23.1067L3.90666 24.2L5.01333 20.1467L4.74666 19.72C3.65333 18 3.06666 15.96 3.06666 13.88C3.08 7.82669 8 2.89335 14.0667 2.89335ZM9.37333 7.77335C9.16 7.77335 8.8 7.85335 8.49333 8.18669C8.2 8.52002 7.33333 9.33335 7.33333 10.9467C7.33333 12.5734 8.52 14.1334 8.66666 14.36C8.85333 14.5867 11.0133 17.92 14.3333 19.3334C15.12 19.6934 15.7333 19.8934 16.2133 20.04C17 20.2934 17.72 20.2534 18.2933 20.1734C18.9333 20.08 20.24 19.3734 20.52 18.6C20.8 17.8267 20.8 17.1734 20.72 17.0267C20.6267 16.8934 20.4133 16.8134 20.08 16.6667C19.7467 16.48 18.12 15.68 17.8267 15.5734C17.52 15.4667 17.3333 15.4134 17.08 15.7334C16.8667 16.0667 16.2267 16.8134 16.04 17.0267C15.84 17.2534 15.6533 17.28 15.3333 17.12C14.9867 16.9467 13.92 16.6 12.6667 15.48C11.68 14.6 11.0267 13.52 10.8267 13.1867C10.6667 12.8667 10.8133 12.6667 10.9733 12.52C11.12 12.3734 11.3333 12.1334 11.4667 11.9334C11.64 11.7467 11.6933 11.6 11.8 11.3867C11.9067 11.16 11.8533 10.9734 11.7733 10.8134C11.6933 10.6667 11.0267 9.01335 10.7467 8.36002C10.48 7.72002 10.2133 7.80002 10 7.78669C9.81333 7.78669 9.6 7.77335 9.37333 7.77335Z"
				fill={cor}
			/>
		</svg>
	);
};
