import { IconeWhatsAppTransparente } from 'assets/icons/IconeWhatsAppTransparente';
import { linkWhatsApp } from 'shared/constants/links';
import * as S from './BotaoWhatsAppMobile.styles';

export const BotaoWhatsAppMobile = () => {
	const clickWhatsApp = () => window.open(linkWhatsApp, '_blank');

	return (
		<S.Botao onClick={clickWhatsApp} aria-label="Botão WhatsApp">
			<IconeWhatsAppTransparente />
		</S.Botao>
	);
};
