import { IconeInstagramBotao } from 'assets/icons/IconeInstagramBotao';
import { linkInstagram } from 'shared/constants/links';
import * as S from './BotaoInstagram.styles';

export const BotaoInstagram = () => {
	const clickInstagram = () => window.open(linkInstagram, '_blank');

	return (
		<S.Container onClick={clickInstagram}>
			<IconeInstagramBotao />
		</S.Container>
	);
};
