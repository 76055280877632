import { css } from '@emotion/react';
import background from 'assets/background.webp';

export const EstilosGlobais = css`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html,
	body,
	#root {
		height: 100%;
	}

	body {
		height: 100%;
		background: url(${background}) center center;
		background-size: cover;
	}

	button {
		outline: none;
	}

	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	input[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	::-webkit-scrollbar {
		width: 16px;
	}

	::-webkit-scrollbar-thumb {
		position: absolute;
		border-radius: 12px;
		background-color: #c1c1c1;
		border: 4px solid #fdfdfd;
	}
`;
