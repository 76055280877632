import { IconeWhatsAppPadrao } from 'assets/icons/IconeWhatsAppPadrao';
import { linkWhatsApp } from 'shared/constants/links';
import * as S from './BotaoWhatsAppWeb.styles';

export const BotaoWhatsAppWeb = () => {
	const clickWhatsApp = () => window.open(linkWhatsApp, '_blank');

	return (
		<S.Container onClick={clickWhatsApp}>
			<S.Botao>
				<IconeWhatsAppPadrao />
				<S.ContainerTexto>
					<span>Realize um orçamento!</span>
					<span>Clique aqui...</span>
				</S.ContainerTexto>
			</S.Botao>
		</S.Container>
	);
};
