import React, { Suspense } from 'react';

type LazyPageParam = Parameters<typeof React.lazy>[0];

export const Lazypage = (page: LazyPageParam) => {
	const Page = React.lazy(page);
	return (
		<Suspense fallback={<></>}>
			<Page />
		</Suspense>
	);
};
